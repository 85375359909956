@import '../../styles/utils';

.showcase-banner {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: auto;
    padding: $spacing-m;
    padding: $spacing-m calc(env(safe-area-inset-right) + #{$spacing-m}) $spacing-m
        calc(env(safe-area-inset-left) + #{$spacing-m});

    color: $neutral-0;

    background-color: $accent-1-80;

    @include media-query-min($breakpoint-medium-lbound) {
        padding-right: $spacing-l;
        padding-right: calc(env(safe-area-inset-right) + #{$spacing-l});
        padding-left: $spacing-l;
        padding-left: calc(env(safe-area-inset-left) + #{$spacing-l});
    }
}

.showcase-banner__icon {
    margin-right: $spacing-m;

    @include media-query-min($breakpoint-medium-lbound) {
        margin-right: $spacing-l;
    }
}

.showcase-banner__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-query-min($breakpoint-medium-lbound) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
    }
}

.showcase-banner__text {
    @include media-query-min($breakpoint-medium-lbound) {
        max-width: rem-calc(590);
    }
}

.showcase-banner__cta {
    flex-shrink: 0;
    margin-top: $spacing-m;

    @include media-query-min($breakpoint-medium-lbound) {
        margin-top: 0;
        margin-left: auto;
    }
}
