// Fonts
@import 'worksans.css';
@import url('https://assets.meilleursagents.com/web/gemini/fonts/20231201/poppins/font-face.css');

// Helpers
@import '~@ma-react/styles/dist/helpers';

// Base
@import '~@ma-react/styles/dist/base';

// Variables
@import './utils/variables';

// Reach
@import '@reach/dialog/styles.css';

// Global

@include media-query-max(sm) {
    @include spacing-helpers('-sm');
}

/* stylelint-disable-next-line selector-max-id */
#__next {
    height: 100%;
}

.mapboxgl-map {
    height: 100%;

    font: unset !important;
    font-size: unset !important;
}

.mapboxgl-popup-content {
    max-width: rem-calc(304) !important;
    padding: 0 !important;

    background-color: $neutral-0 !important;
    border-radius: rem-calc(8) !important;
}

.mapboxgl-popup-close-button {
    font-size: 1.33rem;
}

.list-reset {
    padding: 0;

    list-style: none;
}

// reset figure margins
figure {
    margin: 0;
}
