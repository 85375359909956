@import '../../styles/utils';

.default-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: $navbar-height;
}

.default-layout__banners {
    flex: 0 0 auto;
}

.default-layout__body {
    flex: 1 1 auto;
}
