// Sizes
$navbar-size: 56px;
$size-icon-s: rem-calc(48);
$size-icon-md: rem-calc(73);

// z-index
$z-grid-debug: 999;
$z-loading-overlay: 2;

// Colors
$agency-color: #d11d13;
$sold-property-color: #1e91ff;

// @tokens Colors
$neutral-0: #fff;
$neutral-20: #f5f5f5;
$primary-70: #1432be;
