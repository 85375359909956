@import '../../styles/utils';

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-loading-overlay;

    display: block;
    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.8);
}
