/* Temporary file taken from https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans.css */
/* stylelint-disable */

@font-face {
    font-weight: 400;
    font-family: WorkSans;
    font-style: normal;
    src: url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-regular.woff2')
            format('woff2'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-regular.woff')
            format('woff'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-regular.ttf')
            format('truetype'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-regular.eot')
            format('eot'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-regular.svg')
            format('svg');
    font-display: swap;
}
@font-face {
    font-weight: 500;
    font-family: WorkSans;
    font-style: normal;
    src: url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-medium.woff2')
            format('woff2'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-medium.woff')
            format('woff'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-medium.ttf')
            format('truetype'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-medium.eot')
            format('eot'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-medium.svg')
            format('svg');
    font-display: swap;
}
@font-face {
    font-weight: 600;
    font-family: WorkSans;
    font-style: normal;
    src: url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibold.woff2')
            format('woff2'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibold.woff')
            format('woff'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibold.ttf')
            format('truetype'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibold.eot')
            format('eot'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibold.svg')
            format('svg');
    font-display: swap;
}
@font-face {
    font-weight: 400;
    font-family: WorkSans;
    font-style: italic;
    src: url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-italic.woff2')
            format('woff2'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-italic.woff')
            format('woff'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-italic.ttf')
            format('truetype'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-italic.eot')
            format('eot'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-italic.svg')
            format('svg');
    font-display: swap;
}
@font-face {
    font-weight: 500;
    font-family: WorkSans;
    font-style: italic;
    src: url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-mediumitalic.woff2')
            format('woff2'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-mediumitalic.woff')
            format('woff'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-mediumitalic.ttf')
            format('truetype'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-mediumitalic.eot')
            format('eot'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-mediumitalic.svg')
            format('svg');
    font-display: swap;
}
@font-face {
    font-weight: 600;
    font-family: WorkSans;
    font-style: italic;
    src: url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibolditalic.woff2')
            format('woff2'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibolditalic.woff')
            format('woff'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibolditalic.ttf')
            format('truetype'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibolditalic.eot')
            format('eot'),
        url('https://assets.meilleursagents.com/web/common/fonts/worksans/202104/worksans-semibolditalic.svg')
            format('svg');
    font-display: swap;
}

body,
html {
    font-family: WorkSans, -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu,
        cantarell, Open Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
